<template>
    <div class="create-deal-modal">
      <div class="app-modal__header p-5 d-flex f-between w-100">
          <p class="large--title m-0">
              <span class="mr-5">
                Filter
              </span>
          </p>
          <div class="mobil">
            <el-button class="bg-none-style"   size="medium" @click="close()"  >
                <!-- {{ $t("message.close") }} -->
                <i class="fa-solid fa-xmark"></i>
            </el-button>
          </div>
      </div>
      <div class="content-in__title filter-title w-100">
          <div class="step-block-deals-from w-100">
            <div class="block-sarche">
                <div class="header__search">
                    <el-form ref="form" :model="filterForm" class="el-form-item-0">
                        <el-form-item class="mb-3">
                          <el-input
                              :class="mode ? 'filter__day' : 'filter__night'"
                              clearable
                              size="medium"
                              v-model="filterForm.deal_id"
                              :placeholder="$t('message.n')"
                              class=" w-100"
                          ></el-input>
                        </el-form-item>

                        <el-form-item class="mb-3">
                          <select-company
                              :size="'medium'"
                              :placeholder="$t('message.company')"
                              :id="filterForm.company_id"
                              :class="mode ? 'input__day' : 'input__night'"
                              :deal_users="true"
                              v-model="filterForm.company_id"
                          >
                          </select-company>
                        </el-form-item>

                        <el-form-item class="mb-3">
                          <select-client
                              :size="'medium'"
                              :placeholder="$t('message.client')"
                              :id="filterForm.client_id"
                              :class="mode ? 'input__day' : 'input__night'"
                              :company_id="filterForm.company_id"
                              v-model="filterForm.client_id"
                          >
                          </select-client>
                        </el-form-item>

                        <el-form-item  class="mb-3" v-if="authUser.is_super_admin">
                          <select-user
                              :size="'medium'"
                              :placeholder="$t('message.user')"
                              :class="mode ? 'input__day' : 'input__night'"
                              :id="filterForm.user_id"
                              v-model="filterForm.user_id"
                          >
                          </select-user>
                        </el-form-item>

                        <el-form-item class="mb-3">
                          <select-filial
                              :size="'medium'"
                              :placeholder="$t('message.filial')"
                              :class="mode ? 'input__day' : 'input__night'"
                              :id="filterForm.filial_id"
                              v-model="filterForm.filial_id"
                          >
                          </select-filial>
                        </el-form-item>

                        <el-form-item  class="mb-3" :class="mode ? 'checkbox__day' : 'checkbox__night'">
                          <el-checkbox v-model="filterForm.showDealsWithOffer" label="Сделки с предложением" border style="background: white"></el-checkbox>
                        </el-form-item>

                        <el-form-item  class="mb-3">
                          <el-date-picker
                            class="w-100"
                            v-model="filterForm.created_range"
                            :size="'small'"
                            type="datetimerange"
                            :class="mode ? '' : 'datapicker__night'"
                            align="right"
                            :start-placeholder="$t('message.from')"
                            :end-placeholder="$t('message.to')"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:01:00', '23:59:00']">
                          </el-date-picker>
                        </el-form-item>

                    </el-form>
                </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/inventory/select-company-client";
export default {
    name: 'GoCrmFrontedFilter',
    components: {
      selectUser,
      selectFilial,
      selectCompany,
      selectClient
    },
    data() {
        return {
            filterForm: {},
          	waiting: false
        };
    },
	   computed: {
			...mapGetters({
				authUser: "auth/user",
				mode: "MODE"
			}),
   	},
	   watch: {
			filterForm: {
				handler: async function(newVal, oldVal) {
					this.$emit('input', this.filterForm)

				},
				deep: true,
				immediate: true
			},
      },

    mounted() {

    },

    methods: {
		 submit() {
        this.$emit('input', this.filterForm)
        this.close()
      },
      close(){
        this.$parent.$parent.filterDrawerVisible = false;
      }
    },
  
};
</script>

<style lang="scss" scoped>

@media only screen and (max-width: 1566px){
  .input__day {
      width: 100% !important;
  }
}
</style>
