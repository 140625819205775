<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.client')"
      filterable
      clearable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(client, index) in clients"
        :key="'clients-' + index"
        :label="client.full_name"
        :value="client.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    company_id: {
      default: null,
    },
    model: {
      default: null,
    },
  },
  watch: {
    company_id: {
      handler: function () {
        if (this.company_id) {
          this.selected = null;
          this.$emit("input", null);
          this.companyClients(this.company_id).then((res) => {
            this.clients = this.getPersonClients;
            if (this.selected && this.model && this.company_id != this.model.company_id) {
              this.$emit("input", null);
            }
          });
        } else {
          this.personClients().then((res) => {
            this.clients = this.getPersonClients;
            if (this.selected) {
              this.$emit("input", null);
            }
          });
        }
      },
      immediate: true,
    },
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      clients: []
    };
  },
  // mounted() {
  //   if (this.clients && this.clients.length === 0) this.updateInventory()
  // },
  computed: {
    ...mapGetters({
      getCompanyClients: "clients/companyClients",
      getPersonClients: "clients/personClients",
    }),
  },
  methods: {
    ...mapActions({
      companyClients: "clients/companyClients",
      personClients: "clients/personClients",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
