import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import draggable from "vuedraggable";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/inventory/select-company-client";
import SelectNotificationTypes from '@/components/inventory/select-notification-types';


export default {
	props: {
		project_id: {
		default: null
		}
	},
	components: {
		draggable,
		selectCompany,
		selectClient,
		selectUser,
		selectFilial,
		SelectNotificationTypes,
	},

	data() {
		return {
			skletonDrag: [],
			checked: [],
			dialogVisible: false,
			filterDrawerVisible: false,
			form: {},
			rows: [],
			dateColorClass: 'coloa2',
			loadingData: false,
			filterForm: {},
			drawerDealCreate: false,
			drawerDealUpdate: false,
			titleChangedRow: null,
			colorPicker: "#409EFF",
			newTaskTitle: "",
			drawerCreate: false,
			drawerBoardCreate: false,
			drawerBoardUpdate: false,
			drag: false,
			selectedItem: null,
			reloadList: false,
			board_id: null,
			selectedDeal: null,
			dealCreate: false,
			loadingButton: false,
			windowWidth: 0,
			dynamicEvent: "dblclick",
			dragOptionsMobil2: false,
			dragOptionsMobil: false,
			notification_form: {
				type_id: null,
				user_ids: [],
				text: '',
				activity_time: '',
				notificationable_type: "deals",
				notificationable_id: null
			},
			usersList: [],
			notification_loading: false,
			drawerDeleteDeal: false,
			deal_id: null,
			oldMovedItem: {}
		};
	},

	computed: {
		...mapGetters({
			permissions: "auth/permissions",
			authUser: "auth/user",
			default_list_for_loader: "boards/default_list_for_loader",
			list: "boards/list",
			filter: "boards/filter",
			mode: "MODE",
			users: 'users/inventory',
			rules: 'systeamNotification/rules'
		}),
		board_list(){
			if (!_.isEmpty(this.list)) {
				return _.filter(this.list, {'event': true});
			}
			return [];
		},
		boardDealsSumm: function() {
			let self = this;
			return function (rowIndex){
				let summ = 0;
				if (self.rows[rowIndex].id){
					summ = _.sumBy(self.rows[rowIndex].deals, deal => +(deal.money_amount * deal.currency_rate));
				}
				return summ;
				// return Number(summ.toFixed(2));
			}
		},
		totalSumm: function() {
			let summ = 0;
			if (!_.isEmpty(this.rows)){
				this.rows.forEach((row, index) => {
					if (index != 0) 
						summ += _.sumBy(row.deals, deal => +(deal.money_amount * deal.currency_rate));
				});
			}
			return isNaN(summ) ? 0 : summ;
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
		noMore() {
			return this.count >= 20;
		},
		disabled() {
			return this.loading || this.noMore;
		},
	},
	
	watch: {
		filterForm: {
		handler: async function (newVal, oldVal) {
			await this.updateFilter(newVal);
			this.debouncedGetList();
		},
		deep: true,
		immediate: true,
		},
		list: {
		handler: function (newVal, oldVal) {
			this.rows = JSON.parse(JSON.stringify(this.list));
		},
		deep: true,
		immediate: true,
		},
		loadingData: {
		handler: function (newVal, oldVal) {
			if(newVal && this.rows.length == 0){
				this.rows = JSON.parse(JSON.stringify(this.default_list_for_loader));
			}
		},
		deep: true,
		immediate: true,
		},
		windowWidth() {
			this.handleResize();
		},
	},

	methods: {
		...mapActions({
			updateList: "boards/index",
			changeBoardIndex: "boards/changeIndex",
			destroyBoard: "boards/destroy",
			updateFilter: "boards/updateFilter",
			changeDealIndex: "deals/changeIndex",
			empty: "deals/empty",
			getBoardDeals: "boards/getBoardDeals",
			excelFileDownload: "deals/excelFileDownload",
			usersInventory: 'users/inventoryForFilter',
			saveNotification: 'systeamNotification/store',
			archivateDeal: "deals/archivateDeal",
		}),
		handleDealCommand({action, deal_id}){
			if(action == 'showDialog'){
				this.showDialog(deal_id);
			}else if(action == 'archivatingDealParent'){
				this.archivatingDealParent(deal_id);
			}else if(action == 'deleteDealParent'){
				this.deleteDealParent(deal_id);
			}
		},
		setToLocalStorage() {
			localStorage.setItem("dealId", this.checked);
		},
		winWidth() {
			setInterval(() => {
			const windowWidth = window.innerWidth;
			if (windowWidth >= 1100) {
				this.dragOptionsMobil = true;
			}
			else {
				this.dragOptionsMobil = false;
			}
		}, 100);
		},
		winWidth2() {
			setInterval(() => {
			const windowWidth = window.innerWidth;
			if (windowWidth >= 1100) {
				this.dragOptionsMobil2 = "";
			} else {
				this.dragOptionsMobil2 = "!enabled";
			}
		}, 100);
		},
		handleResize() {
			setInterval(() => {
				const windowWidth = window.innerWidth;
				if (windowWidth <= 1100) {
					this.dynamicEvent = "click";
				}
				else {
					this.dynamicEvent = "dblclick";
				}
			}, 100);
		},
		archivatingDealParent(deal_id) {
			this.$confirm(
				this.$t('message.do_you_want_to_archivate_task'),
				this.$t('message.warning'),
				{
					confirmButtonText: "Да",
					cancelButtonText: "Нет",
					type: "warning",
				}
			)
				.then(() => {
					this.listChanged();
					this.archivateDeal(deal_id).then(() => {
					this.closeAddModel();
					});
					this.$message({
					type: "success",
					message: this.$t('message.archivating_completed'),
					});
				})
				.catch(() => {
				this.$message({
				type: "warning",
				message: this.$t('message.archivating_canceled'),
				});
			});
		},
		deleteDealParent(deal_id) {
			this.drawerDeleteDeal = true;
			this.deal_id = deal_id;
		},
		deleteDealClose(val) {
			this.drawerDeleteDeal = false;
			if (val) {
				this.closeAddModel();
			}
		},
		saveSysteamNotification() {
			if (!this.loadingButton) {
				if (!this.notification_form.activity_time) {
					this.$message({
					showClose: true,
					message: 'must be active time',
					type: 'warning'
					});
				}
				else {
					this.$refs['notification_form'].validate((valid) => {
					if (valid) {
						this.loadingButton = true;
						this.saveNotification(this.notification_form)
							.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.dialogVisible = false;
							}).catch((err) => {
							this.$alert(err);
							});
					}
					});
				}
			}
		},
		handleDelete(index, row) {
			this.notification_form.user_ids.splice(this.notification_form.user_ids.indexOf(row.id), 1);
		},
		showDialog(deal_id) {
			this.dialogVisible = true;
			this.notification_form.notificationable_id = deal_id;
		},
		openDialog() {
			this.usersInventory({ all_users: 'all_users' }).then((res) => {
				this.usersList = JSON.parse(JSON.stringify(this.users));
				this.notification_form.user_ids.push(this.authUser.id);
			});
		},
		closedDialog() {
			this.notification_form.type_id = null;
			this.notification_form.user_ids = [];
			this.notification_form.activity_time = '';
			this.notification_form.text = '';
			this.notification_form.notificationable_id = null;
		},
		searchUsersWithLoad(val) {
			let ids = this.notification_form.user_ids;
			if (val) {
				this.notification_loading = true;
				this.searchUsers(val, ids);
			}
			else {
				this.notification_loading = true;
				this.usersInventory({ user_ids: ids }).then((res) => {
					this.notification_loading = false;
					this.usersList = JSON.parse(JSON.stringify(this.users));
				}).catch((err) => {
					console.log(err);
				});
			}
		},
		searchUsers: _.debounce(function (val, ids) {
			this.usersInventory({ search_val: val, ids: ids }).then(() => {
				this.notification_loading = false;
				this.usersList = JSON.parse(JSON.stringify(this.users));
			}).catch((err) => {
				console.log(err);
			});
			}, 1000
		),
		setChangeCheckbox(status) {
			if (status) {
				this.disabled_users_select = true;
			}
			else this.disabled_users_select = false;
		},
		downloadExcelFile() {
			this.excelFileDownload()
				.then((res) => {
					let blob = new Blob([res.data], {
					type: "application/vnd.ms-excel"
					})
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob)
					link.setAttribute("download", 'excel.xlsx');
					document.body.appendChild(link);
					link.click();
				})
				.catch((err) => {
					this.$alert(err);
				});
		},
		getMoreBoardDeals(id, count) {
			let filter = { ...this.filterForm, id: id, skip_count: count }
			this.loadingButton = true;
			this.getBoardDeals(filter).then(res => {
				this.loadingButton = false
			}).catch(err => {
				this.loadingButton = false
			});
		},
		dayDiff(element) {
			if (element.end_date) {
				let finish_date = new Date(element.finish_date);
				let current_date = new Date();
				let end_date = new Date(element.end_date);
				if (!isNaN(current_date) && !isNaN(end_date)) {
					if (!isNaN(finish_date) && finish_date.getFullYear() !== 1970) {
						let timeDifference = Math.round((end_date.getTime() - finish_date.setHours(0, 0, 0)) / (1000 * 3600 * 24));
						return timeDifference;
					} else {
						let timeDifference = Math.round((end_date.getTime() - current_date.setHours(0, 0, 0)) / (1000 * 3600 * 24));
						return timeDifference;
					}
				}
			}
			else return '';
		},
		addModalCreate(board, dealCreate) {
			this.board_id = board.id;
			this.selectedDeal = null;
			this.dealCreate = dealCreate;
			this.drawerDealCreate = true;
		},
		addModalEdit(board, deal, dealCreate) {
			this.board_id = board.id;
			this.selectedDeal = deal;
			this.dealCreate = dealCreate;
			this.drawerDealCreate = true;
		},
		closeAddModel() {
			this.drawerDealCreate = false;
			if (this.reloadList === true) {
				this.debouncedGetList();
			}
		},
		getList() {
			this.loadingData = true;
			let query = {...this.filter, project_id: this.project_id};
			this.updateList(query)
				.then(() => {
					this.rows = JSON.parse(JSON.stringify(this.list));
					this.reloadList = false;
					this.loadingData = false;
				})
				.catch(() => {
					this.loadingData = false
					this.$message({
						type: "info",
						message: "There is no tabs",
				});
			});
		},
		colorCheck(percetColor) {
			if (percetColor > 85) {
				return "PrimaryPercet";
			}
			else if (percetColor > 65) {
				return "SuccessPercet";
			}
			else if (percetColor > 45) {
				return "WarningPercet";
			}
			else {
				return "DangerPercet";
			}
		},
		checkMoveBoard(item){
			if (item.draggedContext.element.event && item.relatedContext.element.event) {
				return true;
			}
			return false;
		},
		changeBoardIndexEvent(event) {
			if(this.authUser.is_super_admin){
				let old_item = event.moved.newIndex;
				old_item = (event.moved.newIndex > event.moved.oldIndex) ? (old_item - 1) : (old_item + 1);
				this.changeBoardIndex({
					id: event.moved.element.id,
					old_id: this.rows[old_item].id,
				});
			}
		},
		onStart(row){
			if (row) {
				this.oldMovedItem = row.item._underlying_vm_; 
				this.$set(this.oldMovedItem, 'oldIndex', row.oldIndex);
			} 
		},
		onUpdate(event, row, rowIndex){
			let movedDeal = event.item._underlying_vm_;
			if (rowIndex != 0) {
				this.changeDealIndex({
					moved_deal_id: movedDeal.id,
					moved_deal_board_id: row.id,
					newIndex: event.newIndex
				});
			}
			else {
				const oldIndex = event.oldIndex;
				const newIndex = event.newIndex;

				if (oldIndex !== newIndex) {
					// Revert changes by moving the item back to its original position
					this.rows[rowIndex].deals.splice(oldIndex, 0, this.rows[rowIndex].deals.splice(newIndex, 1)[0]);
				}
			};
		},
		onAdd(event, row, rowIndex){
			if (row.id && row.type_board == 'sorted') {
				let movedDeal = event.item._underlying_vm_;
				
				if (!movedDeal.board_id){
					this.rows[0].deals_count -= 1;
				}
				else {
					let targetRowIndex = _.findIndex(this.rows, o => (o.id == this.oldMovedItem.board_id));
					this.rows[targetRowIndex].deals_count -= 1;
				}
				this.rows[rowIndex].deals_count += 1;

				let isUnsortedDeal = !movedDeal.board_id ? true : false;
				
				this.$set(this.rows[rowIndex].deals[event.newIndex], 'board_id', row.id);
				this.$set(this.rows[rowIndex].deals[event.newIndex], 'index', event.newIndex);
				
				this.changeDealIndex({
					moved_deal_id: movedDeal.id,
					moved_deal_board_id: row.id,
					newIndex: event.newIndex,
					isUnsortedDeal: isUnsortedDeal
				});
			}
			else if(!row.id && row.type_board == 'unsorted'){
				const movedDeal = this.rows[0].deals.splice(event.newIndex, 1)[0];
				let targetRowIndex = _.findIndex(this.rows, function(o) { return o.id == movedDeal.board_id; });
				if (targetRowIndex) {
					this.rows[targetRowIndex].deals.splice(this.oldMovedItem.oldIndex, 0, movedDeal);
				}
			}
		},
		transferDeal(board_id, element){
			let isUnsortedDeal = !element.board_id ? true : false;
			let query = {moved_deal_id: element.id, moved_deal_board_id: board_id, newIndex: 0, isUnsortedDeal: isUnsortedDeal};

			this.changeDealIndex(query).then(() => {
				this.debouncedGetList();
				this.board_id = null;
			});
		},
		openDrawer() {
		this.drawerDealCreate = !this.drawerDealCreate;
		},
		deleteBoard(row) {
			this.$confirm(
				this.$t('message.do_you_want_to_delete_board'),
				this.$t('message.warning'),
				{
					confirmButtonText: this.$t('message.yes'),
					cancelButtonText: this.$t('message.no'),
					type: "warning",
				}
			)
			.then(() => {
				this.destroyBoard(row.id)
				.then(res => {
					if (res.data.result.success) {
						this.debouncedGetList();
						this.$alert(res);
					} else {
						this.$warning_message(res);
					}

				})
				.catch(() => {
				});
			})
			.catch(() => {
			});
		},
		changeTitle(event, row) {
			let _el = event.target;
			_el.nextElementSibling.classList.add("active");
			_el.classList.add("_focus");
			_el.setAttribute("contenteditable", true);
		},
		editTaskTitle(row) {
			this.drawerBoardUpdate = true;
			this.selectedItem = row;
		},
		changeTaskTitle() {
			this.titleChangedRow.title = this.newTaskTitle;
			this.titleChangedRow.titleDefaultColor = this.colorPicker;

			this.editTaskTitleModal = false;
		},
		changeColor(val) {
			this.colorPicker = val;
		},
		closeDrawer(drawer) {
			this.drawerBoardCreate = false;
			this.drawerBoardUpdate = false;
			if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
				this.$refs[drawer].closeDrawer();
			}
		},
		edit(model) {
			this.selectedItem = model;
			this.drawerUpdate = true;
		},
		drawerClosed(ref) {
			if (this.$refs[ref]) {
				this.$refs[ref].closed();
			}
			if (this.reloadList === true) {
				this.debouncedGetList();
			}
			if (_.isFunction(this.empty)) {
				this.empty();
			}
		},
		drawerOpened(ref) {
			if (this.$refs[ref]) {
				if (_.isFunction(this.$refs[ref].opened)) {
					this.$refs[ref].opened();
				}
			}
		},
		listChanged() {
			this.reloadList = true;
		},
		destroy(model) {
			this.destroyBoard(model.id)
				.then((res) => {
				this.$alert(res);
				this.fetchData();
				})
				.catch((err) => {
				this.$alert(err);
				});
		},
	},

}