<template>
	<div class="card-table mb-0  border-none pch-M height-M92"  style="margin-top: -8px" >
		<div class="card-table-body card-table-body i-i ifon-add-title-block pb-0 pl-0 mb-0">
			<div class="header__flex">
				<div class="content-in__title filter-title">
					<h1 class="content-title mr-3 ml-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{
						$t("message.deals") }}
					</h1>
					<div class="header__search mobil-none-d">
						<el-form ref="form" :model="filterForm" class="d-flex el-form-item-0 mobil-i-s-n-none">
							<el-form-item class="ml__null">
								<el-input 
									:class="mode ? 'filter__day' : 'filter__night'" 
									clearable 
									:size="'mini'"
									v-model="filterForm.deal_id" 
									:placeholder="$t('message.n')" 
									class="id_input "
								>
								</el-input>
							</el-form-item>

							<el-form-item>
								<select-company 
									:size="'mini'" 
									:placeholder="$t('message.company')" 
									:id="filterForm.company_id"
									:class="mode ? 'input__day' : 'input__night'" 
									:deal_users="true" 
									v-model="filterForm.company_id"
								>
								</select-company>
							</el-form-item>

							<el-form-item>
								<select-client 
									:size="'mini'" 
									:placeholder="$t('message.client')" 
									:id="filterForm.client_id"
									:class="mode ? 'input__day' : 'input__night'" 
									:company_id="filterForm.company_id"
									v-model="filterForm.client_id"
								>
								</select-client>
							</el-form-item>

							<el-form-item v-if="authUser.is_super_admin">
								<select-user 
									:size="'mini'" 
									:placeholder="$t('message.user')"
									:class="mode ? 'input__day' : 'input__night'" 
									:id="filterForm.user_id" 
									v-model="filterForm.user_id"
								>
								</select-user>
							</el-form-item>

							<el-form-item>
								<select-filial 
									:size="'mini'" 
									:placeholder="$t('message.filial')"
									:class="mode ? 'input__day' : 'input__night'" 
									:id="filterForm.filial_id" 
									v-model="filterForm.filial_id"
								>
								</select-filial>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="content__setting m-wrap">
					<span class="align-items-center align-self-center pr-2 j-sum">
						<span :class="mode ? 'price__day' : 'price__night blue__night'" class="el-tag el-tag--light">
							{{ $t("message.total_amount") }}: {{ totalSumm | formatMoney(2) }}
						</span>
					</span>

					<div class="custom__button  pr-2">
						<el-button class="standard-btn" @click="filterDrawerVisible = true" size="small">
							<i class="fa-solid fa-filter mr-0"></i>
						</el-button>
					</div>

					<div class="custom__button ">
						<el-button class="standard-btn" @click="downloadExcelFile()" icon="el-icon-document-delete" size="small">
							{{ $t("message.excel") }}
						</el-button>
					</div>

					<div class="custom__button ml-2  deale-but-mobil">
						<el-button class="standard-btn" v-if="permissions.some(per => per.slug == 'boards.create')"
							icon="el-icon-s-order" size="small" @click="drawerBoardCreate = true">{{ $t("message.create_board") }}
						</el-button>
					</div>
				</div>
			</div>

			<div class="tasks__relative tasks__relative2">
				<div class="tasks__absolute">
					<div class="deal-track-wrap" :class="mode ? 'scrollx__day' : 'scrollx__night'">
						<draggable
							:list="rows"
							:move="checkMoveBoard"
							@change="changeBoardIndexEvent"
							:sort="dragOptionsMobil"
							:disabled="dragOptionsMobil2"
							class="d-flex break__word  mobil-Scroll"
						>
							<div 
								class="deal-track-items rounded hidden"
								:class="{ minBlock: checked.some((id) => id == row.id) }"
								v-for="(row, rowIndex) in rows"
								:key="row.id"
							>
								<el-skeleton :loading="loadingData" animated>
									<template slot="template">
										<div class="deal-track-header content-title content-title--medium p-3 d-flex f-between align-center">
											<el-skeleton-item variant="text" style="width: 31px; height: 20px;">
												<div>
													<label class="tag checked-click">
													</label>
												</div>
											</el-skeleton-item>
											<el-skeleton-item variant="text" style="width: 207px; height: 20px;">
												<span class="d-block w-100 pointer name-doska">

												</span>
											</el-skeleton-item>
											<el-skeleton-item variant="text" style="width: 21px; height: 20px;">
												<div :class="mode ? 'plus__day' : 'plus__night'" class="add-task mr-2">
												</div>
											</el-skeleton-item>
											<el-skeleton-item variant="text" style="width: 18px; height: 20px;">
												<div class="track-header-menu pointer">

												</div>
											</el-skeleton-item>
										</div>
									</template>
									<template>
										<div 
											class="deal-track-header content-title content-title--medium p-3 d-flex f-between align-center"
											:style="{ 'border-color': row.color }"
										>
											<div>
												<label class="tag checked-click" :style="{ background: row.color }" :for="row.id">
												<i class="fa-regular fa-eye eye-open"></i>
												<i class="fa-regular fa-eye-slash eye-off"></i>
												<input v-model="checked" type="checkbox" name="a" :id="row.id" :value="row.id"
													@change="setToLocalStorage" />
												</label>
											</div>
											<span class="d-block w-100 pointer name-doska">
												{{ row.name }}
											</span>
											<div 
												:class="mode ? 'plus__day' : 'plus__night'" 
												class="add-task mr-2"
												@click="permissions.some(per => per.slug == 'deals.create') ? addModalCreate(row, true) : ''"
												v-if="permissions.some(per => per.slug == 'tasks.create') && row.event"
											>
												<i class="el-icon-plus"></i>
											</div>
											<div class="track-header-menu pointer" v-show="row.event">
												<el-dropdown trigger="click">
													<span class="el-dropdown-link">
														<i class="el-icon-more-outline icon-color tr-r-90" style="font-size: 18px"></i>
													</span>
													<el-dropdown-menu slot="dropdown" class="task-track-dropdown">
														<el-dropdown-item v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'taskBoards.update')">
															<el-button type="text" icon="el-icon-edit" size="medium" @click="editTaskTitle(row)">
																{{ $t('message.update') }}
															</el-button>
														</el-dropdown-item>
														<el-dropdown-item v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'taskBoards.delete')">
															<el-button type="text" icon="el-icon-delete" size="medium" @click="deleteBoard(row)">
																{{ $t('message.delete') }}
															</el-button>
														</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
										</div>
									</template>
								</el-skeleton>

								<el-skeleton  :loading="loadingData" animated>
									<template slot="template">
										<div class="summa-task d-flex f-between" :class="mode ? 'summa__day' : 'summa__night'">
											<el-skeleton-item variant="text" style="width: 97px; height: 17.25px;">
												<span> {{ boardDealsSumm(rowIndex) | formatMoney(2) }} </span>
											</el-skeleton-item>
											<el-skeleton-item variant="text" style="width: 17.25px; height: 17.25px;">
												<span>
													{{ row.deals_count }}
												</span>
											</el-skeleton-item>
										</div>
									</template>
									<template>
										<div class="summa-task d-flex f-between" :class="mode ? 'summa__day' : 'summa__night'">
											<span> {{ boardDealsSumm(rowIndex) | formatMoney(2) }} </span>
											<span>
												{{ row.deals_count }}
											</span>
										</div>
									</template>
								</el-skeleton>

								<div class="deal-track-body deal-track-body2" :class="mode ? 'overflow__day' : 'overflow__night'">
									<draggable
										class="list-group"
										v-model="row.deals"
										@start="onStart"
										@add="onAdd($event, row, rowIndex)"
										@update="onUpdate($event, row, rowIndex)"
										:group="{ name: 'row' }"
										v-bind="dragOptions"
										:sort="dragOptionsMobil"
										:disabled="dragOptionsMobil2"
										tag="ul"
									>
										<li class="list-group-item" v-for="(element, elementIndex) in row.deals" :key="'element-'+elementIndex" type="primary">
											<div>
												<el-skeleton :loading="loadingData" animated>
													<template slot="template">
														<div class="control-over" :class="mode ? 'tasks__day' : 'tasks__night'">
															<div class="deal-task-items deal-task-items-ik p-3 mb-1">
																<div class="deal-task-items-left">
																	<el-skeleton-item variant="image" style="width: 245px; height: 16px;" class="d-flex f-between mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="type-task-big mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="type-task-big mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="type-task-big mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="type-task-big mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="type-task-big mb-2" />
																	<el-skeleton-item variant="text" style="width: 245px; height: 16px;" class="bottom-data-task" />
																</div>
																<div class="deal-task-items-right">
																	<el-skeleton-item variant="image" style="width: 20px; height: 16px;" class="el-dropdown" />
																	<el-skeleton-item variant="image" style="width: 20px; height: 16px;" class="el-popover-deals-hover" />
																	<el-skeleton-item variant="image" style="width: 20px; height: 16px;" class="el-popover-deals-hover" />
																	<el-skeleton-item variant="image" style="width: 20px; height: 16px;" class="data-deal-task-start mr-0" />
																</div>
															</div>
														</div>
													</template>

													<template>
														<div class="control-over" :class="mode ? 'tasks__day' : 'tasks__night'">
															<el-select class="transferTaskSelect" @change="transferDeal(board_id, element)" v-model="board_id" placeholder="Передача Доска">
																<el-option
																	v-for="(board, index) in board_list"
																	:key="'board-'+index"
																	:label="board.name"
																	:value="board.id"
																	:disabled="(board.id == row.id) ? true : false"
																>
																</el-option>
															</el-select>
															<div class="deal-task-items deal-task-items-ik p-3 mb-1">
																<div class="deal-task-items-left" @[dynamicEvent]="permissions.some(per => per.slug == 'deals.update') ? addModalEdit(row, element, false) : ''">
																	<div class="d-flex f-between mb-2">
																	<span class="nomer-deal-task" :class="mode ? 'text__night' : 'text__white'">
																		<span class="pr-1">№{{ element.id }}</span>

																		<span class="offer_style" v-if="element.offer_count > 0">
																		<i class="offer_style el-icon-document"></i>
																		{{ element.offer_count }}
																		</span>

																		<i class="el-icon-arrow-right"></i>

																		<span class="ml-1">
																		{{ element.deal_stage ? element.deal_stage.name : '' }}
																		</span>
																	</span>
																	</div>

																	<div class="type-task-big mb-2">
																		<i class="fa-solid fa-user-tie mr-2 icon-color"
																			:class="mode ? 'text__night' : 'text__white'"></i>
																		<div class="name-tesk" :class="mode ? 'text__night' : 'text__white'">
																			{{ element.name }}
																		</div>
																	</div>

																	<div class="type-task-big mb-2">
																		<i class="fa-regular fa-building mr-2 icon-color"
																			:class="mode ? 'text__night' : 'text__white'"></i>
																		<div class="name-tesk" :class="mode ? 'text__night' : 'text__white'">
																			{{ element.filial ? element.filial.name : '' }}
																		</div>
																	</div>

																	<div class="type-task-big mb-2">
																		<i class="fa-solid fa-list-check mr-2 icon-color"
																			:class="mode ? 'text__night' : 'text__white'"></i>
																		<div class="name-tesk" :class="mode ? 'text__night' : 'text__white'">
																			{{ element.deal_type ? element.deal_type.name : '' }}
																		</div>
																	</div>

																	<div class="type-task-big mb-2">
																		<i class="fa-solid fa-dollar-sign mr-2 icon-color"
																			:class="mode ? 'text__night' : 'text__white'"></i>
																		<div class="name-tesk dollar-e" :class="mode ? 'text__night' : 'text__white'">
																			{{ element.money_amount | formatNumber(1) }}
																			{{ element.currency ? element.currency.code : '' }}
																		</div>
																	</div>

																	<div class="type-task-big mb-2">
																		<span>
																			<a href="#" class="link-color">
																			{{ element.user ? element.user.name : '' }}
																			</a>
																		</span>
																	</div>

																	<div class="bottom-data-task">
																		<span class="data-deal-task-end" :class="dayDiff(element) >= 0 ? 'coloa2' : 'coloa3'">
																			<i class="el-icon-time"></i>
																			{{ formatDate(element.begin_date) }}
																			<i class="el-icon-arrow-right"></i>
																			{{ formatDate(element.end_date) }}
																			<i class="el-icon-arrow-right"></i>
																			{{ dayDiff(element) }}
																		</span>
																	</div>
																</div>
																<div class="deal-task-items-right">
																	<el-dropdown :hide-on-click="true" trigger="click" @command="handleDealCommand">
																		<span class="el-dropdown-link">
																			<i class="my-tirtel fa-solid fa-bars-staggered"></i>
																		</span>
																		<el-dropdown-menu slot="dropdown">
																			<el-dropdown-item :command="{action: 'showDialog', deal_id: element.id}">
																					<span >{{ $t('message.calendarNotification') }}</span>
																			</el-dropdown-item>
																			<el-dropdown-item
																				v-if="permissions.some((per) => per.slug == 'deals.archivate')"
																				:command="{action: 'archivatingDealParent', deal_id: element.id}">
																				<span> {{ $t('message.archivate_deal') }} </span>
																			</el-dropdown-item>
																			<el-dropdown-item
																				v-if="permissions.some((per) => per.slug == 'deals.delete')"
																				:command="{action: 'deleteDealParent', deal_id: element.id}">
																				<span> {{ $t('message.delete_deal') }} </span>
																			</el-dropdown-item>
																		</el-dropdown-menu>
																	</el-dropdown>
																	<el-popover class="el-popover-deals-hover" placement="left" popper-class="width-placement" trigger="hover">
																		<div class="">
																			{{ element.phone }}
																		</div>
																		<el-button slot="reference"><i class="fa-solid fa-phone-volume"></i></el-button>
																	</el-popover>
																	<el-popover class="el-popover-deals-hover" placement="left" popper-class="width-placement" trigger="hover">
																		<div class="">
																			{{ element.email }}
																		</div>
																		<el-button slot="reference"><i class="fa-solid fa-at"></i></el-button>
																	</el-popover>
																	<span class="data-deal-task-start  mr-0" :class="colorCheck(Math.round(element.data_percentage))">
																		<span>{{ Math.round(element.data_percentage) }}</span>
																		<span class="d-pratsent">%</span>
																	</span>
																</div>
															</div>
														</div>
													</template>
												</el-skeleton>
											</div>	
										</li>
									</draggable>

									<li v-show="row.deals.length < row.deals_count" class="list-group-item" key="more_deals">
										<el-button @click="getMoreBoardDeals(row.id, row.deals.length)" v-loading="loadingButton" type="primary" size="medium" class="w-100">
											{{ $t('message.more') }}
										</el-button>
									</li>

								</div>
							</div>
						</draggable>
					</div>
				</div>
			</div>

			<!-- Create New Task -->
			<el-dialog :class="mode ? 'l-modal-style' : 'd-modal-style'" class="create-task-modal modal-style-board"
				:title="$t('message.board_name')" :with-header="false" :visible.sync="drawerBoardCreate" ref="drawerBoardCreate"
				@opened="drawerOpened('drawerBoardCreateChild')" @closed="drawerClosed('drawerBoardCreateChild')">
				<crm-create-board :selectedItem="selectedItem" :project_id="project_id" ref="drawerBoardCreateChild"
				drawer="drawerBoardCreate"></crm-create-board>
			</el-dialog>
			<!-- End Create New Task -->

			<!-- Edit Task Title -->
			<el-dialog :class="mode ? 'l-modal-style' : 'd-modal-style'" class="create-task-modal  modal-style-board "
				:title="$t('message.board_name')" :with-header="false" :visible.sync="drawerBoardUpdate" ref="drawerBoardUpdate"
				@opened="drawerOpened('drawerBoardUpdateChild')" @closed="drawerClosed('drawerBoardUpdateChild')" width="30%">
				<crm-update-board :selectedItem="selectedItem" :project_id="project_id" ref="drawerBoardUpdateChild"
				drawer="drawerBoardUpdate"></crm-update-board>
			</el-dialog>
			<!-- Edit End Task Title -->

			<div class="app-modal app-modal__full bg-seriq">
				<el-drawer ref="drawerDealCreate" :with-header="false" :class="mode ? 'l-modal-style' : 'd-modal-style'"
				class="create-task-modal " :visible.sync="drawerDealCreate" @opened="drawerOpened('drawerDealCreateChild')"
				@closed="drawerClosed('drawerDealCreateChild')" size="96%">

				<AppModal ref="drawerDealCreateChild" :board_id="board_id" :selectedDeal="selectedDeal" :dealCreate="dealCreate"
					@addModalEdit="closeAddModel()" drawer="drawerDealCreate" />
				</el-drawer>
			</div>

			<el-dialog class="el-dialog-w-100 el-dialog-w-100" :title="$t('message.notifications_nn')" :visible.sync="dialogVisible" width="30%" @open="openDialog"
				@closed="closedDialog">
				<el-form ref="notification_form" :model="notification_form" :rules="rules" label-position="top">
				<el-form-item :label="$t('message.notificationTypes')" prop="type_id">
					<select-notification-types v-model="notification_form.type_id"
						:id="notification_form.type_id"></select-notification-types>
				</el-form-item>
				<el-form-item :label="$t('message.text')" prop="text">
					<el-input type="textarea" v-model="notification_form.text"></el-input>
				</el-form-item>
				<el-form-item :label="$t('message.activity_time')">
					<el-col :span="11">
						<el-date-picker type="datetime" :placeholder="$t('message.activity_time')"
						v-model="notification_form.activity_time" style="width: 100%;" format="yyyy-MM-dd HH:mm"
						value-format="yyyy-MM-dd HH:mm">
						</el-date-picker>
					</el-col>
				</el-form-item>
				<el-form-item :label="$t('message.users')" prop="user_ids">
					<el-select v-model="notification_form.user_ids" multiple collapse-tags filterable
						:placeholder="$t('message.users')" :loading="notification_loading" class="w-100">
						<el-option v-for="item in usersList" :key="'item-'+item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-row>
					<el-button @click="saveSysteamNotification" :loading="loadingButton" type="success">{{ $t('message.save')
					}}</el-button>
					<el-button @click="dialogVisible = false" type="danger">{{ $t('message.close') }}</el-button>
				</el-row>
				</el-form>
			</el-dialog>

			<el-drawer :with-header="false" :append-to-body="true" :class="mode ? 'l-modal-style' : 'd-modal-style'" class="create-task-modal "
				:visible.sync="filterDrawerVisible" size="50%">
				<div>
				<Myfilter v-model="filterForm"></Myfilter>
				</div>
			</el-drawer>

			<el-dialog  :class="mode ? 'l-modal-style' : 'd-modal-style'" class="KP_modal el-dialog-w-100 " :title="$t('message.delete')"
				:visible.sync="drawerDeleteDeal" :append-to-body="true" width="30%" center ref="drawerDeleteDeal"
				@opened="drawerOpened('deleteDealComponent')" @closed="drawerClosed('deleteDealComponent')">
				<delete-deal 
					ref="dealOfferComponent" 
					:deal_id="deal_id ? deal_id : ''" 
					@deleteDealClose="deleteDealClose"
					drawer="drawerDealOfferCreate"
				>
				</delete-deal>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import deals from '@/utils/mixins/models/deals';
	import AppModal from "./components/add-modal";
	import CrmCreateBoard from "./components/board/crm-create-board";
	import CrmUpdateBoard from "./components/board/crm-update-board";
	import CrmCreateDeal from "./components/deal/crm-create-deal";
	import CrmUpdateDeal from "./components/deal/crm-create-deal";
	import DeleteDeal from "./components/deleteDeal/delete-deal";
	import Myfilter from './components/my-filter';

	export default {
		name: "deals",
		mixins: [deals],
		components:{
			AppModal,
			CrmCreateBoard,
			CrmUpdateBoard,
			CrmCreateDeal,
			CrmUpdateDeal,
			DeleteDeal,
			Myfilter
		},
		mounted() {
			this.winWidth();
			this.winWidth2();
			this.handleResize();

			const ids = localStorage.getItem("dealId");
			if (ids && ids.length) {
				this.checked = ids.split(',');
			}
		},
		created() {
			this.filterForm = JSON.parse(JSON.stringify(this.filter));
			this.debouncedGetList = _.debounce(this.getList, 500);
			// this.handleResize();
		},
		beforeRouteLeave(to, from, next){
			this.$store.commit("boards/EMPTY_LIST");
			this.rows = [];
			next();
   	}
	};
</script>

<style>
	.offer_style {
		background: rgb(11, 224, 11);
		color: white;
		border-radius: 3px;
		border: 1px solid rgb(11, 224, 11);
		padding: 1px;
	}

	.summa-task {
		background: #fff;
		padding: 10px;
		font-size: 15px;
		font-weight: 600;
	}

	.coloa3 {
		background: #ff3f3f;
	}

	.type-task-big {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.type-task {
		color: #686868;
	}

	.bottom-data-task {
		margin-top: 5px;
	}

	.bottom-data-task span {
		margin-left: 0px;
		margin-right: 5px;
	}

	.ml__null {
		margin-left: 0 !important;
	}

	.el-checkbox .is-bordered {
		border-radius: 10px !important;
	}

	.datapicker__night {
		background-color: #323248 !important;
		border: 1px solid #323248 !important;
		border-radius: 10px !important;
		height: 32px !important;
	}

	.datepicker__night input {
		background: #323248 !important;
		color: #fff !important;
	}

	.el-date-editor .el-range-separator {
		color: rgb(169, 169, 169) !important;
	}

	.el-date-editor .el-range-input {
		background: inherit !important;
	}

	.datapicker__night .el-range-input {
		color: #fff !important;
	}

	.add-task i:hover {
		color: var(--element-color);
	}
	.bg-seriq .el-drawer{
		background-color: #eef2f4;
	}
	.bg-seriq  .el-collapse-item__header{
		background-color: #fff;
		margin-left: 0px !important;
		padding-left: 20px;
	}
</style>
