<template>
    <div class="task-form">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item class="label_mini mb-1" prop="name">
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item class="label_mini mb-1" prop="filial_id">
                        <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
							:project_id="project_id"
                        >
                        </select-filial>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <div class="bg-color-span-board">
                        <div
                            class="colors"
                            v-for="item in tagColors"
                            :key="item.tag"
                            :class="[item.tag == tag_name ? 'addClassChec' : '']"
                        >
                            <label
                                class="checked-click mir"
                                :class="item.tag"
                                :for="item.tag"
                                @click="setClick(item.tag)"
                            >
                                <input
                                    v-model="item.checked"
                                    type="radio"
                                    name="item"
                                    :id="item.tag"
                                    @change="setColor(item.color)"
                                    :value="item.color"
                                />
                                <i class="el-icon-check"></i>
                            </label>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-form>

        <el-button
            class="w-100"
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="submit(true)"
        >
            {{ $t("message.create") }}
        </el-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectFilial from "@/components/inventory/select-filial";

export default {
    mixins: [form, drawer],
	 props: ['project_id'],
    components: {
        selectFilial,
    },
    data() {
        return {
            checked: false,
            tag_name: "",
            tagColors: [
                {
                    tag: "tag1",
                    color: "#D7E0EF",
                    checked: false,
                },
                {
                    color: "#E7ECCA",
                    checked: false,
                    tag: "tag2",
                },
                {
                    color: "#F7F0D3",
                    checked: false,
                    tag: "tag3",
                },
                {
                    color: "#F7CECD",
                    checked: false,
                    tag: "tag4",
                },
                {
                    color: "#FEE5CD",
                    checked: false,
                    tag: "tag5",
                },
                {
                    color: "#FFF1CC",
                    checked: false,
                    tag: "tag6",
                },
                {
                    color: "#D9EAD4",
                    checked: false,
                    tag: "tag7",
                },
                {
                    color: "#D0E1E4",
                    checked: false,
                    tag: "tag8",
                },
                {
                    color: "#CEE3F4",
                    checked: false,
                    tag: "tag9",
                },
                {
                    color: "#DAD4EA",
                    checked: false,
                    tag: "tag10",
                },
                {
                    color: "#ECD3DD",
                    checked: false,
                    tag: "tag11",
                },
                {
                    color: "#0000001A",
                    checked: false,
                    tag: "tag12",
                },
                {
                    color: "#B5C3DA",
                    checked: false,
                    tag: "tag13",
                },
                {
                    color: "#D0D9A0",
                    checked: false,
                    tag: "tag14",
                },
                {
                    color: "#F5E6B2",
                    checked: false,
                    tag: "tag15",
                },
                {
                    color: "#F09C99",
                    checked: false,
                    tag: "tag16",
                },
                {
                    color: "#FDCB9A",
                    checked: false,
                    tag: "tag17",
                },
                {
                    color: "#FFE496",
                    checked: false,
                    tag: "tag18",
                },
                {
                    color: "#B5D6A8",
                    checked: false,
                    tag: "tag19",
                },
                {
                    color: "#A0C5CB",
                    checked: false,
                    tag: "tag20",
                },
                {
                    color: "#9CC7EA",
                    checked: false,
                    tag: "tag21",
                },
                {
                    color: "#B6ABD8",
                    checked: false,
                    tag: "tag22",
                },
                {
                    color: "#D9A9BF",
                    checked: false,
                    tag: "tag23",
                },
                {
                    color: "#DADADA",
                    checked: false,
                    tag: "tag24",
                },
                {
                    color: "#9EACC3",
                    checked: false,
                    tag: "tag25",
                },
                {
                    color: "#B7C37B",
                    checked: false,
                    tag: "tag26",
                },
                {
                    color: "#E1C65E",
                    checked: false,
                    tag: "tag27",
                },
                {
                    color: "#E86863",
                    checked: false,
                    tag: "tag28",
                },
                {
                    color: "#FCB265",
                    checked: false,
                    tag: "tag29",
                },
                {
                    color: "#FFD85D",
                    checked: false,
                    tag: "tag30",
                },
                {
                    color: "#90C37B",
                    checked: false,
                    tag: "tag31",
                },
                {
                    color: "#71A6B1",
                    checked: false,
                    tag: "tag32",
                },
                {
                    color: "#66ABDF",
                    checked: false,
                    tag: "tag33",
                },
                {
                    color: "#8F81C6",
                    checked: false,
                    tag: "tag34",
                },
                {
                    color: "#C87EA2",
                    checked: false,
                    tag: "tag35",
                },
                {
                    color: "#C6C6C6",
                    checked: false,
                    tag: "tag36",
                },
                {
                    color: "#6F81A2",
                    checked: false,
                    tag: "tag37",
                },
                {
                    color: "#90A248",
                    checked: false,
                    tag: "tag38",
                },
                {
                    color: "#CFA630",
                    checked: false,
                    tag: "tag39",
                },
                {
                    color: "#DA3734",
                    checked: false,
                    tag: "tag40",
                },
                {
                    color: "#FA8935",
                    checked: false,
                    tag: "tag41",
                },
                {
                    color: "#FFC12F",
                    checked: false,
                    tag: "tag42",
                },
                {
                    color: "#5EA248",
                    checked: false,
                    tag: "tag43",
                },
                {
                    color: "#3F7988",
                    checked: false,
                    tag: "tag44",
                },
                {
                    color: "#3680CC",
                    checked: false,
                    tag: "tag45",
                },
                {
                    color: "#5D4EA6",
                    checked: false,
                    tag: "tag46",
                },
                {
                    color: "#A94B74",
                    checked: false,
                    tag: "tag47",
                },
                {
                    color: "#A6A6A6",
                    checked: false,
                    tag: "tag48",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            model: "boards/model",
            rules: "boards/rules",
            columns: "boards/columns",
            mode: "MODE",
        }),
    },
    methods: {
        ...mapActions({
            save: "boards/store",
            empty: "boards/emptyModel",
        }),
        // changeColor(val) {
        //     this.form.color = val;
        // },
        setColor(color) {
            this.form.color = color;
        },
        setClick(tag) {
            this.tag_name = tag;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
						  this.form.project_id = this.project_id;
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                            this.empty();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
		  afterLeave(){
			this.$store.commit('boards/EMPTY_MODEL');
		  }
    },
};
</script>
